import { gql } from '@apollo/client/core'

export const getExpense = gql`
  query getExpense($expense_id: ID!) {
    getExpense(expenseId: $expense_id) {
      expenseId
      companyId
      expenseNotes
      expensePaidFull
      expenseTotal
      expenseType
      vendorId
      receiptNumber
      expenseStatus
      expenseSourceAccountId
      createdAt
      updatedAt
      expenseAccount {
        expenseId
        expenseStatus
        expenseAccountId
        expenseAccountName
        expenseAccountType
        expenseAccountTypeId
        createdAt
        updatedAt
      }
      expenseFiles {
        expenseFileId
        expenseFileKey
        expenseFileUrl
        expenseStatus
        fileId
        updatedAt
        createdAt
      }
      expenseItems {
        expenseItemId
        expenseId
        expenseItemTotal
        expenseStatus
        expenseItemName
        itemId
        quoteId
        vendorId
        createdAt
        updatedAt
      }
    }
  }
`

export const getExpenseCount = gql`
  query getExpenseCount($filters: ExpensesFilterInput!) {
    getExpenseCount(filters: $filters) {
      count
      totalAmount
      totalFiles
    }
  }
`

export const getExpenses = gql`
  query getExpenses($filters: ExpensesFilterInput!, $limit: Int, $offset: Int) {
    getExpenses(filters: $filters, limit: $limit, offset: $offset) {
      expenseId
      companyId
      expenseNotes
      expensePaidFull
      expenseTotal
      expenseType
      vendorId
      receiptNumber
      expenseStatus
      expenseSourceAccountId
      createdAt
      updatedAt
      expenseAccount {
        expenseId
        expenseStatus
        expenseAccountId
        expenseAccountName
        expenseAccountType
        expenseAccountTypeId
        createdAt
        updatedAt
      }
      expenseFiles {
        expenseFileId
        expenseFileKey
        expenseFileUrl
        expenseStatus
        fileId
        updatedAt
        createdAt
      }
      expenseItems {
        expenseItemId
        expenseId
        expenseItemTotal
        expenseStatus
        expenseItemName
        itemId
        quoteId
        createdAt
        updatedAt
      }
    }
  }
`

export const getExpenseItem = gql`
  query getExpenseItem($expense_item_id: ID!) {
    getExpenseItem(expenseItemId: $expense_item_id) {
      expenseItemId
      expenseId
      expenseItemTotal
      expenseStatus
      itemId
      quoteId
      vendorId
      createdAt
      updatedAt
    }
  }
`

export const getExpenseItems = gql`
  query getExpenseItems($filters: ExpenseItemsFilterInput!) {
    getExpenseItems(filters: $filters) {
      expenseItemId
      expenseId
      expenseItemTotal
      expenseItemName
      expenseStatus
      quoteId
      itemId
      vendorId
      createdAt
      updatedAt
      expensePaidFull
      receiptNumber
      expenseNotes
    }
  }
`

export const createExpense = gql`
  mutation createExpense($expense: CreateExpenseInput!) {
    createExpense(expense: $expense) {
      expenseId
      companyId
      expenseNotes
      expensePaidFull
      expenseTotal
      expenseType
      vendorId
      receiptNumber
      expenseStatus
      expenseSourceAccountId
      createdAt
      updatedAt
      expenseItems {
        expenseItemId
        expenseId
        expenseItemName
        expenseItemTotal
        expenseStatus
        itemId
        quoteId
        createdAt
        updatedAt
      }
      expenseAccount {
        expenseId
        expenseStatus
        expenseAccountId
        expenseAccountName
        expenseAccountType
        expenseAccountTypeId
        createdAt
        updatedAt
      }
      expenseFiles {
        expenseFileId
        expenseFileKey
        expenseFileUrl
        expenseStatus
        fileId
        updatedAt
        createdAt
      }
    }
  }
`

export const updateExpense = gql`
  mutation updateExpense($expense_id: ID!, $expense: UpdateExpenseInput!) {
    updateExpense(expenseId: $expense_id, expense: $expense) {
      expenseId
      companyId
      expenseNotes
      expensePaidFull
      expenseTotal
      expenseType
      vendorId
      receiptNumber
      expenseStatus
      expenseSourceAccountId
      createdAt
      updatedAt
      expenseAccount {
        expenseId
        expenseStatus
        expenseAccountId
        expenseAccountName
        expenseAccountType
        expenseAccountTypeId
        createdAt
        updatedAt
      }
      expenseFiles {
        expenseFileId
        expenseFileKey
        expenseFileUrl
        expenseStatus
        fileId
        updatedAt
        createdAt
      }
      expenseItems {
        expenseItemId
        expenseId
        expenseItemTotal
        expenseItemName
        expenseStatus
        itemId
        quoteId
        createdAt
        updatedAt
      }
    }
  }
`

export const createExpenseItem = gql`
  mutation createExpenseItem($expenseItem: CreateExpenseItemInput!) {
    createExpenseItem(expenseItem: $expenseItem) {
      expenseItemId
      expenseId
      expenseItemTotal
      expenseStatus
      itemId
      quoteId
      createdAt
      updatedAt
    }
  }
`

export const updateExpenseItem = gql`
  mutation updateExpenseItem($expense_item_id: ID!, $expenseItem: UpdateExpenseItemInput!) {
    updateExpenseItem(expenseItemId: $expense_item_id, expenseItem: $expenseItem) {
      expenseItemId
      expenseId
      expenseItemTotal
      expenseStatus
      itemId
      quoteId
      createdAt
      updatedAt
    }
  }
`

export const deleteExpense = gql`
  mutation deleteExpense($expense_id: ID!) {
    deleteExpense(expenseId: $expense_id)
  }
`

export const getExpenseFilePresignPost = gql`
  mutation getExpenseFilePresignPost($contentType: String!) {
    getExpenseFilePresignPost(contentType: $contentType)
  }
`

export const processExpenseFiles = gql`
  mutation processExpenseFiles($expenseFiles: ProcessExpenseFilesInput!) {
    processExpenseFiles(expenseFiles: $expenseFiles) {
      receiptNumber
      totalAmount
      vendorName
      expenseType
      receiptItems {
        expenseItemName
        expenseItemTotal
        expenseItemQuantity
      }
    }
  }
`

export const processExpenseItems = gql`
  mutation processExpenseItems($expenseItems: ProcessExpenseItemsInput!) {
    processExpenseItems(expenseItems: $expenseItems) {
      linkedItems {
        expenseItemName
        itemId
      }
    }
  }
`
