import { gql } from '@apollo/client/core'

const ItemFields = gql`
  fragment ItemFields on Item {
    item_id
    stage_id
    phase_id
    item_net
    item_type
    item_gross
    item_net
    start_date
    end_date
    invoice_status
    invoice_id
    item_name
    quote_id
    item_status
    approval {
      id
      quoteId
      itemId
      nextStepRole
      nextStepStatus
      approvalSteps {
        id
        order
        status
        approvers {
          id
          userId
          role
          authority
        }
        timeApproved
        timeCreated
        timeUpdated
        timeDeleted
      }
      history {
        id
        approverId
        approvalStepId
        approvalLogStatus
        timeApproved
        timeUpdated
        timeCreated
        oAuthorization
      }
      files {
        file_name
        file_type
        id
        file_url
      }
      timeApproved
      timeCreated
      timeUpdated
      timeDeleted
    }
  }
`

const GroupedFields = gql`
  fragment GroupedFields on Group {
    title
    items {
      item_id
      stage_id
      phase_id
      item_net
      item_type
      item_gross
      item_net
      start_date
      end_date
      invoice_status
      invoice_id
      item_name
      quote_id
      item_status
      approval {
        id
        quoteId
        itemId
        nextStepRole
        nextStepStatus
        approvalSteps {
          id
          order
          status
          approvers {
            id
            userId
            role
            authority
          }
          timeApproved
          timeCreated
          timeUpdated
          timeDeleted
        }
        history {
          id
          approverId
          approvalStepId
          approvalLogStatus
          timeApproved
          timeUpdated
          timeCreated
          oAuthorization
        }
        files {
          file_name
          file_type
          id
          file_url
        }
        timeApproved
        timeCreated
        timeUpdated
        timeDeleted
      }
    }
    item_line_type
    id
    approverId
    paymentRequestId
    paymentRequestStatus
    totalApproved
    totalCost
    hasInvoice
    invoiceStatus
  }
`
const PaymentRequestFields = gql`
  fragment PaymentRequestFields on PaymentRequest {
    paymentRequestId
    paymentSegmentId
    phaseId
    stageId
    quoteId
    paymentRequestStatus
    paymentRequestAmount
    paymentRequestTimeSent
    paymentRequestTimeDue
    paymentRequestTimeCreated
    paymentRequestTimeLastModified
  }
`

const PaymentTermRequestFields = gql`
  fragment PaymentTermRequestFields on PaymentTerm {
    paymentTermId
    quoteId
    companyId
    paymentTermType
    paymentTermShowDetails
    paymentTermTimeCreated
    paymentTermTimeLastModified
    segments {
      paymentSegmentId
      paymentTermId
      paymentSegmentPercentage
      paymentSegmentRequestSent
      paymentSegmentRequestTimeSent
      paymentSegmentTimeDue
      paymentSegmentStatus
      paymentSegmentName
      paymentSegmentDesc
      paymentSegmentTimeCreated
      paymentSegmentTimeLastModified
      paymentSegmentOwner
      paymentSegmentCreator
      paymentSegmentOrder
      paymentRequestId
      paymentRequestStatus
      quoteId
      companyId
      progress
      total
    }
  }
`

export const getGroupedApprovalsQuery = gql`
  ${GroupedFields}
  query getGroupedApprovals(
    $quoteId: ID!
    $companyId: ID!
    $approverId: ID!
    $skipApproval: Boolean
  ) {
    getGroupedApprovals(
      quoteId: $quoteId
      companyId: $companyId
      approverId: $approverId
      skipApproval: $skipApproval
    ) {
      ...GroupedFields
    }
  }
`

export const getPaymentRequestsQuery = gql`
  ${PaymentRequestFields}
  query getPaymentRequests($quoteId: ID!) {
    getPaymentRequests(quoteId: $quoteId) {
      ...PaymentRequestFields
    }
  }
`

export const sendPaymentRequestQuery = gql`
  ${PaymentRequestFields}
  query sendPaymentRequest($paymentRequestId: ID!) {
    sendPaymentRequest(paymentRequestId: $paymentRequestId) {
      ...PaymentRequestFields
    }
  }
`

export const getPaymentTermQuery = gql`
  ${PaymentTermRequestFields}
  query getPaymentTerm($quoteId: ID!, $companyId: ID!) {
    getPaymentTerm(quoteId: $quoteId, companyId: $companyId) {
      ...PaymentTermRequestFields
    }
  }
`

export const getGroupedPaymentRequestsQuery = gql`
  ${GroupedFields}
  query getGroupedPaymentRequests($quoteId: ID!, $companyId: ID!, $approverId: ID!) {
    getGroupedPaymentRequests(quoteId: $quoteId, companyId: $companyId, approverId: $approverId) {
      ...GroupedFields
    }
  }
`

export const getCostItemsQuery = gql`
  ${ItemFields}
  query getCostItems($quoteId: ID!, $companyId: ID!, $approverId: ID!) {
    getCostItems(quoteId: $quoteId, companyId: $companyId, approverId: $approverId) {
      ...ItemFields
    }
  }
`

export const getBilledPaymentRequestsQuery = gql`
  ${GroupedFields}
  query getBilledPaymentRequests($quoteId: ID!, $companyId: ID!, $approverId: ID!) {
    getBilledPaymentRequests(quoteId: $quoteId, companyId: $companyId, approverId: $approverId) {
      ...GroupedFields
    }
  }
`
