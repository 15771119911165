import { gql } from '@apollo/client/core'

export const AI_ASK = gql`
  query Ask(
    $prompt: String!
    $model: String
    $searchTypes: [String]
    $structuredOutput: JSON # Reference to the input type defined in the schema
    $fileIds: [String]
    $codeInterpreter: Boolean
  ) {
    ask(
      prompt: $prompt
      model: $model
      searchTypes: $searchTypes
      structuredOutput: $structuredOutput
      fileIds: $fileIds
      codeInterpreter: $codeInterpreter
    ) {
      results {
        text
        relevanceScore
      }
      options {
        text
        relevanceScore
      }
      searchResults {
        id
        index
        content
        relevanceScore
      }
    }
  }
`

export const GET_SUMMARY = gql`
  query changeSummary($changeSet: AWSJSON!, $context: String) {
    getChangeSummary(changeSet: $changeSet, context: $context)
  }
`

export default {
  AI_ASK
}
