import { client } from '../apollo-config'
import { GET_SUMMARY } from '@/apollo-client/api-calls/ai'

export async function fetchSummary({ changeSet, context = '' }) {
  const apolloClient = client()
  const { data } = await apolloClient.query({
    query: GET_SUMMARY,
    variables: {
      changeSet,
      context
    }
  })
  return data.getChangeSummary
}
