import { client } from '../apollo-config'
import {
  getBilledPaymentRequestsQuery,
  getCostItemsQuery,
  getGroupedApprovalsQuery,
  getGroupedPaymentRequestsQuery,
  getPaymentRequestsQuery,
  getPaymentTermQuery,
  sendPaymentRequestQuery
} from '../api-calls/paymentTerm'

const handleError = async (asyncFn) => {
  try {
    return await asyncFn()
  } catch (error) {
    console.log('error: ', error)
    return null
  }
}

export async function getGroupedApprovals({
  quoteId,
  companyId,
  approverId,
  skipApproval = false,
  accessToken
}) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getGroupedApprovalsQuery,
      variables: {
        quoteId,
        companyId,
        approverId,
        accessToken,
        skipApproval
      }
    })
    return data.getGroupedApprovals
  })
}

export async function getGroupedPaymentRequests({ quoteId, companyId, approverId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getGroupedPaymentRequestsQuery,
      variables: {
        quoteId,
        companyId,
        approverId,
        accessToken
      }
    })
    return data.getGroupedPaymentRequests
  })
}

export async function getCostItems({ quoteId, companyId, approverId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getCostItemsQuery,
      variables: {
        quoteId,
        companyId,
        approverId,
        accessToken
      }
    })
    return data.getCostItems
  })
}

export async function getPaymentRequests({ quoteId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getPaymentRequestsQuery,
      variables: {
        quoteId,
        accessToken
      }
    })
    return data.getPaymentRequests
  })
}

export async function sendPaymentRequest({ paymentRequestId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: sendPaymentRequestQuery,
      variables: {
        paymentRequestId,
        accessToken
      }
    })
    return data.sendPaymentRequest
  })
}

export async function getBilledPaymentRequests({ quoteId, companyId, approverId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getBilledPaymentRequestsQuery,
      variables: {
        quoteId,
        companyId,
        approverId,
        accessToken
      }
    })
    return data.getBilledPaymentRequests
  })
}

export async function getPaymentTerm({ quoteId, companyId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getPaymentTermQuery,
      variables: {
        quoteId,
        companyId,
        accessToken
      }
    })
    return data.getPaymentTerm
  })
}
