import { costingClient as client } from '../ApolloClients.js'

import {
  getExpense as getExpenseGQL,
  getExpenseCount as getExpenseCountGQL,
  getExpenses as getExpensesGQL,
  getExpenseItem as getExpenseItemGQL,
  getExpenseItems as getExpenseItemsGQL,
  getExpenseFilePresignPost as getExpenseFilePresignPostGQL,
  createExpense as createExpenseGQL,
  updateExpense as updateExpenseGQL,
  updateExpenseItem as updateExpenseItemGQL,
  createExpenseItem as createExpenseItemGQL,
  processExpenseFiles as processExpenseFilesGQL,
  processExpenseItems as processExpenseItemsGQL,
  deleteExpense as deleteExpenseGQL
} from '../api-calls/expense.ts'

import type { ExpenseSchema } from '@bolster/types/entity/expense/expense.ts'
import type { ExpenseCreateSchema } from '@bolster/types/entity/expense/expense_create.ts'
import type { ExpenseUpdateSchema } from '@bolster/types/entity/expense/expense_update.ts'

import type { ExpenseItemSchema } from '@bolster/types/entity/expense_item/expense_item.ts'
import type { ExpenseItemCreateSchema } from '@bolster/types/entity/expense_item/expense_item_create.ts'
import type { ExpenseItemUpdateSchema } from '@bolster/types/entity/expense_item/expense_item_update.ts'

import type { IFetchFilters } from '@bolster/types/filters/fetch_filters.ts'
import type { ExpenseProcessedFile } from '@bolster/types/entity/expense_file/expense_processed_file.ts'
import type { IExpenseFetchFilters } from '@bolster/types/filters/entity/expense_fetch_filters.ts'

export async function getExpense(expense_id: string): Promise<ExpenseSchema> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getExpenseGQL,
    variables: {
      expense_id
    }
  })

  return data.getExpense
}
export async function getExpenseCount(
  filters: Partial<IExpenseFetchFilters>
): Promise<{ count: number; totalAmount: number; totalFiles: number }> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getExpenseCountGQL,
    variables: {
      filters
    }
  })

  return data.getExpenseCount
}
export async function getExpenses(
  filters: Partial<IExpenseFetchFilters>,
  limit: number,
  offset: number
): Promise<ExpenseSchema[]> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getExpensesGQL,
    variables: {
      filters,
      limit,
      offset
    }
  })

  return data.getExpenses
}
export async function getExpenseItem(expense_item_id: string): Promise<ExpenseItemSchema> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getExpenseItemGQL,
    variables: {
      expense_item_id
    }
  })

  return data.getExpenseItem
}
export async function getExpenseItems(
  filters: IFetchFilters<Partial<ExpenseItemSchema>>
): Promise<ExpenseItemSchema[]> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getExpenseItemsGQL,
    variables: {
      filters
    }
  })

  return data.getExpenseItems
}
export async function getExpenseFilePresignPost(contentType: string): Promise<any> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: getExpenseFilePresignPostGQL,
    variables: {
      contentType
    }
  })

  return JSON.parse(data.getExpenseFilePresignPost)
}

export async function createExpense(expense: Partial<ExpenseCreateSchema>): Promise<ExpenseSchema> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: createExpenseGQL,
    variables: {
      expense
    }
  })

  return data.createExpense
}

export async function updateExpense(
  expense_id: string,
  expense: Partial<ExpenseUpdateSchema>
): Promise<ExpenseSchema> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: updateExpenseGQL,
    variables: {
      expense_id,
      expense: {
        ...expense,
        expenseItems: (expense.expenseItems || []).map((item) => ({
          ...item,
          expenseItemTotal: Number(item.expenseItemTotal)
        }))
      }
    }
  })

  return data.updateExpense
}

export async function updateExpenseItem(
  expense_item_id: string,
  expense_item: ExpenseItemUpdateSchema
): Promise<ExpenseItemSchema> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: updateExpenseItemGQL,
    variables: {
      expense_item_id,
      expense_item
    }
  })

  return data.updateExpenseItem
}

export async function createExpenseItem(
  expense_item: ExpenseItemCreateSchema
): Promise<ExpenseItemSchema> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: createExpenseItemGQL,
    variables: {
      expense_item
    }
  })

  return data.createExpenseItem
}

export async function deleteExpense(expense_id: string): Promise<boolean> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: deleteExpenseGQL,
    variables: { expense_id }
  })

  return data.deleteExpense
}

export async function processExpenseFiles(expenseFiles: {
  expenseFileKeys: string[]
}): Promise<ExpenseProcessedFile> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: processExpenseFilesGQL,
    variables: {
      expenseFiles
    }
  })

  return data.processExpenseFiles
}

export async function processExpenseItems(expenseItems: {
  expenseItems: { expenseItemId: string; expenseItemName: string }[]
  quoteItems: { quoteId: string; itemId: string; itemName: string }[]
}): Promise<{ linkedItems: { expenseItemName: string; itemId: string }[] }> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: processExpenseItemsGQL,
    variables: {
      expenseItems
    }
  })

  return data.processExpenseItems
}
